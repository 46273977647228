import { useState } from 'react';
import React from 'react';
import '../css/Nav.css';
import eyes from '../images/eyes.svg'
import logo from '../images/logo-no-background.png'
// import ToggleDarkMode from '../App';


function Nav({ updateCol }) {
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('isDarkMode') === 'true');
    // localStorage.setItem('isDarkMode', isDarkMode);
  
    const handleClick = () => { 
        updateCol(!isDarkMode);
        setIsDarkMode(!isDarkMode);
        localStorage.setItem('isDarkMode', !isDarkMode);
        
      };

    return (
        <div className="topnav">
           <a href="/" className="splitleft"><img src={logo} alt="Logo" width="50" height="50"/></a>
            <a href="gallery">Gallery</a>
            <a href="about">About</a>
            {/* <a href="contact">Contact</a> */}

            
            <img className={`splitright ${isDarkMode ? 'lightmode' : 'darkmode'}`} onClick={handleClick} src={eyes} alt="" width="70" height="70"></img> 
        </div>
    );
};

export default Nav;
