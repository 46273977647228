import React, { useState } from 'react';
import './css/App.css';
import Nav from './components/Nav';
import myPos from './images/myPos.png';
import myPosDark from './images/myPosDark.jpg';
import './BlinkingExclamation.css'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from './components/Gallery';
import Notfound from './components/Notfound';
import About from './components/About';
import Footer from './components/Footer';




function App() {

  if (!localStorage.getItem('isDarkMode')) {
    localStorage.setItem('isDarkMode', 'true');
  }
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('isDarkMode') === 'true');

  const updateCol = (newCol) => {
    setIsDarkMode(newCol);
  };
 

  
  return (
    <div>

      
      <Nav updateCol={updateCol}/>
      <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<header className={`App ${localStorage.getItem('isDarkMode') === 'true' ? "App-dark" : "App-light"}`}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={isDarkMode ? myPos : myPosDark} alt="myPic" style={{ borderRadius: '50%', width: '300px', height: '300px' }} />
        </div>
        <span className="box"><h1 className={`${localStorage.getItem('isDarkMode') === 'true' ? "dark" : "light"}`}>Me VK!</h1></span>
        <p className='fontfamily'>Tech by profession, Seeker by Blessings: a Traveler guided by Bharath.</p>
      </header>}>
          
        </Route>

        <Route exact path='/gallery' element={<Gallery />} />
        <Route exact path='/about' element={<About />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
    <Footer />
      
      

    </div>
  );
}

export default App;
