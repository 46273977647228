import React from 'react';
import '../css/Footer.css';


function Footer({ updateCol }) {


    return (
        <div className="footer">
            <h1>Vk © 2024</h1>
        </div>
    );
};

export default Footer;
