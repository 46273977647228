import React from 'react';
import '../css/About.css';
import myPic from '../images/myPos.png';
import '../css/App.css';
import linkedin from '../images/linkedin.svg';
import gmail from '../images/gmail.svg';
import instagram from '../images/instagram.svg';

function About() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isDarkMode = localStorage.getItem('isDarkMode') === 'true';

    const handleLinkedInClick = () => {
        window.open('https://www.linkedin.com/in/vinay-kiran-k-s-3954b5167/', '_blank');
    };

    const handleEmailClick = () => {
        window.open('mailto:your-email@example.com');
    };
    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/v_k_k_s_?igsh=dm13ZGN3OWNscHdt&utm_source=qr', '_blank');
    }; 

    return (
        <div className={isMobile ? (isDarkMode ? 'aboutmobile App-dark-mobile' : 'aboutmobile App-light-mobile') : (isDarkMode ? 'about App-dark' : 'about App-light')}>
            <img src={myPic} alt="myPic" style={{ borderRadius: '50%', width: '300px', height: '300px' }} />

            <h1>
                <img src={linkedin} alt="linkedin" width="60" height="40" onClick={handleLinkedInClick}></img>
                <img src={gmail} alt="gmail" width="60" height="40" onClick={handleEmailClick}></img>
                <img src={instagram} alt="insta" width="60" height="40" onClick={handleInstagramClick}></img> 
            </h1>

            <p>As a full-stack developer deeply immersed in Golang and DevOps, I weave code to create seamless digital experiences. Beyond coding, I find harmony in exploring spirituality, embracing yoga, and a vegetarian lifestyle. Intrigued by consciousness, I delve into understanding and seek to integrate mindfulness into every aspect of life. My curiosity extends beyond tech; I thrive on meaningful connections and relish engaging with diverse minds. If you're intrigued by the convergence of technology and spirituality or seek stimulating exchanges, I'm always open to connect.<br /><br /> ✉️: vinaykiranks886@gmail.com</p>
        </div>
    );


}

export default About;