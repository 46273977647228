import React from 'react';
import '../css/Gallery.css';
import '../BlinkingExclamation.css'; 


function Gallery() {

    const isDarkMode = localStorage.getItem('isDarkMode') === 'true';
    
    return (
        <div className={isDarkMode ? 'bg-dark' : 'bg-light'}>
            <span className="box"><h1 className={`${localStorage.getItem('isDarkMode') === 'true' ? "dark" : "light"}`}><br/><br/><br/><br/><br/><br/>Coming with Good Pics</h1></span>
        </div>
    );
}

export default Gallery;