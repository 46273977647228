import React from 'react';
import '../css/Gallery.css';
import '../BlinkingExclamation.css'; 
import '../css/App.css';
import '../css/Notfound.css'
import rocket from '../images/rocket.png';

function Notfound() {    
    return (
        <div className='App notfound'>
        <span className="box"><h1 className="not404">Lost In Space Visit Home</h1></span>
        <img src={rocket} alt="earth" width="300" height="300" onClick={() => window.location.href = '/'}></img>    
        </div>
    );
}

export default Notfound;